<template>
	<div
		class="flex-column justify-center bottom"
	>
		<div
			class="bg-login-content box"
		>
			<div
				class=" text-center"
			>
				<div
					class="bg-primary size-px-16 color-white font-weight-bold pa-10 "
				><img :src="require(`@/assets/images${$skin.img}logo_bottom.png`)" class="logo"/></div>
			</div>
			<div class="pa-10">
				<h6>이름</h6>
				<input
					v-model="item.member_name"
					type="text" placeholder="이름을 입력하세요"
					class="input-underline"
					maxlength="20"
				/>
			</div>
			<div
				class=" pa-10"
			>
				<h6>연락처</h6>
				<input
					v-model="item.member_phone"
					type="number" placeholder="연락처를 입력하세요"
					class="input-underline"
					:rules="[$rules.max(item, 'member_phone', 15)]"
				/>
			</div>
			<div class="pa-10 text-center">
				<button
					@click="onConfirm"
					class="btn-fill btn-round"
					:disabled="disabled"
				>아이디 찾기</button>
			</div>
		</div>


		<Modal
			:option="modal_option"
			:is_modal="is_modal"

			@close="is_modal = !is_modal"
		>
			<div
				slot="modal-content"
				class="flex-column justify-center full-height"
			>
				<div
					class="text-center"
				>
					<p class="mt-10">가입하신 아이디는 아래와 같습니다.</p>
					<p class="mt-10 size-px-16 font-weight-bold">{{ search_id }}</p>
				</div>
			</div>

			<div slot="modal-bottom" class="text-center pa-10 bg-white">
				<button
					class="btn-fill btn-round"

					@click="toLogin"
				>확인</button>
			</div>

		</Modal>

		<div
			v-if="is_on_confirm"
			class="position-fixed-full"
		>

			<div
				style="position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5"
			></div>
			<div
				style="position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 9999"
				class="color-white text-center size-px-24"
			>

				<iframe
					v-show="true"
					:src="nice_url"
					class="full-height full-width"
				></iframe>
			</div>
		</div>
	</div>
</template>

<script>

import Modal from "@/components/Modal";

export default{
	name: 'FindId'
	,props: []
	,components: { Modal }
	,data: function(){
		return {
			program: {
				name: '아이디 찾기'
				, bottom: true
				, title: true
				, bland: false
				, wrap: 'loginNew'
				, is_container: true
				, top_line: true
			}
			,member_id: ''
			,item: {
				member_name : ''
				,member_phone : ''
			}
			,is_modal: false
			,modal_option: {
				top: true
				,title: '아이디 찾기 안내'
				,bottom: true
			}
			, is_on_confirm: false
			, nice_url: ''
		}
	}
	,computed: {
		search_id: function(){
			let id = ''
			if(this.member_id){
				let length = this.member_id.length
				let start = Math.ceil(length / 3)
				let end = Math.floor(length / 3)
				console.log('length : ' + length + ' start: ' + start + ' end: ' + end)
				id = this.member_id.slice(0, start)
					+ this.member_id.slice(start, start + end).replace(/(?<=.{0})./gi, "*")
					+ this.member_id.slice(start + end, length)

			}
			return id
		}
		, disabled: function(){
			let t = true
			if(this.item.member_name && this.item.member_phone){
				t = false
			}
			return t
		}
	}
	,methods: {
		confirm: function(){

		}
		,findId: async function(){
			try {
				const result = await this.$request.init({
					method: 'post'
					, url: 'member/getMemberId'
					, data: this.item
				})

				if (result.success) {
					this.member_id = result.data.member_id
					this.is_modal = true
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (E) {
				console.log(E)
				this.$bus.$emit('notify', {type: 'error', message: E})
			}
		}
		,toLogin: function(){
			this.$bus.$emit('to', { name: 'Login'})
		}
		, onConfirm: function(){
			if(this.item.member_name && this.item.member_phone){
				window.open(this.nice_url, 'nice', 'width=320px')
			}
		}
		, niceResult: function(e){
			console.log('niceResult', e.data)
			try {
				let domains = process.env.VUE_APP_DOMAIN.split('|')
				let t = false
				for(let i = 0; i < domains.length; i++){
					if(e.origin.indexOf(domains[i]) > -1){
						t = true
					}
				}
				if(t){
					let data = e?.data
					console.log(data)
					if(data && typeof data == 'string'){
						data = JSON.parse(data)
						if(data.type == 'find'){
							if(data.result){
								if(this.item.member_name == data.data.name && this.item.member_phone == data.data.phone){
									this.findId()
								}else{
									throw "인증정보가 맞지않습니다"
								}
							}else{
								throw data.message
							}
						}
					}
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}

	}
	, destroyed() {
		window.removeEventListener('message', this.niceResult)
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)

		let baseUrl = this.$baseUrl

		this.nice_url = baseUrl + '/nice/request?type=find'

		window.removeEventListener('message', this.niceResult)
		setTimeout( () => {
			window.addEventListener('message', this.niceResult)
		}, 100)
	}
}
</script>

<style>
.bg-login { background-color: #eee; padding: 10px;}
.bg-login-content { background-color: white; border-radius: 5px; min-width: 320px; max-width: 480px; margin: 0 auto}

.auto-login { vertical-align: middle; font-size: 14px; }

.login-menu { }
.login-menu a { font-size: 14px; font-weight: 400}

.toggle-password { position: relative; }
.toggle-password .toggle { position: absolute; right: 10px; top: 20px; color: #bbb}
.toggle-password .toggle.on { color: #0f6ecd}

.error-underline { background: none; border-bottom: 1px solid red !important;}

</style>